import React from 'react';
import Content from '../views/Leasing/Features';

export default ({location}) => <Content location={location} seo={{
  title: "Vehicle management solution for leasing companies",
  lang: 'en',
  description: 'Vehicle management solution for leasing optimizes business operations. Get relevant data and timely reports from vehicles, manage service agreements.',
  meta: [{
    name: 'keywords',
    content: 'Vehicle management'
  }]
}}/>